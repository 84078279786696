<template>
    <Dialog 
        :style="{ width: '70vw', maxHeight: '40vw' }" 
        header="Iniciar agendamentos" 
        :modal="true" 
        :closable="false" 
        :closeOnEscape="false"
        :draggable="false"
    >
        <AppLoadingWrapper v-if="loading" />
        <div v-if="!loading && !hasError" class="mb-3">
            <span>
                Há mais de um agendamento em aberto para o funcionário <strong>{{ records[0].funcionario.name }}</strong>. Selecione quais agendamentos deseja iniciar.
            </span>
        </div>
        <DataTable 
            v-if="!loading && !hasError"
            ref="grid" 
            :value="records"
            v-model:selection="agendamentosSelecionados"
        >
            <Column selectionMode="multiple" style="flex: 0 0 4rem" :exportable="false"></Column>
            <Column field="tipoExame.descricao" header="Tipo de exame" style="minWidth: 10rem"/>
            <Column header="Data de sugestão" style="minWidth: 15rem">
                <template #body="{ data }">
                    {{ $filters.formatDateOnly(data?.dataSugestaoInicial) }} e
                    {{ $filters.formatDateOnly(data?.dataSugestaoFinal) }} no periodo da
                    {{ getLabelPeriodo(data?.periodo).label }}
                </template>
            </Column>
            <Column field="observacao" header="Observação"/>
            <Column field="cliente.particularidades" header="Particularidade do cliente"/>
        </DataTable>
        <div v-if="!loading && hasError" class="flex flex-column align-items-center justify-content-center">
            <i class="pi pi-times-circle mr-3" style="font-size: 2rem"/>
            <span style="font-size: 2rem">Sem dados de exibição!</span>
        </div>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="onClose" />
            <Button 
                label="Iniciar Selecionados" 
                icon="pi pi-check" 
                class="p-button-outlined p-button-success" 
                @click="onConfirm"
                :loading="loadingMultiplosAgendamentos"
            />
        </template>
    </Dialog>
</template>

<script>
import { getClientBase } from '@/services/http';

export default {
    emits:['onConfirm', 'onClose'],
    props: {
        loadingMultiplosAgendamentos: {
            type: Boolean
        },
        agendamentoIds: {
            type: Array,
            required: true
        }
    },
    
    data() {
        return {
            loading: false,
            hasError: false,
            records: [],
            agendamentosSelecionados: [],
            periodos: [
                { label: 'Manhã', value: 1 },
                { label: 'Tarde', value: 2 }
            ]
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            this.loading = true;
            await this.loadAgendamentos();
            this.loading = false;
        },
        async loadAgendamentos() {
            try {
                this.hasError = false;
                const ids = this.agendamentoIds?.join(',');
                const { data } = await getClientBase().get(`/agendamentos/buscar-varios/${ids}`);
                this.records = data;
            } catch (error) {
                this.hasError = true;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar informações!', life: 3000 });
            }
        },
        onClose() {
            this.$emit('onClose');
        },
        async onConfirm() {
            const agendamentoIds = this.agendamentosSelecionados?.map(p => p.id);
            this.$emit('onConfirm', agendamentoIds);
            await this.load();
        },
        getLabelPeriodo(value) {
            const periodo = this.periodos.find(p => p.value === value);
            return periodo || { label: 'Não informado' };
        }
    }
}
</script>
