<template>
    <Dialog :breakpoints="{ '1000px': '60vw', '650px': '80vw' }" :style="{ width: '40vw' }"
        :header="agendamento.funcionario.name ? 'Comentários no agendamento de ' + agendamento.funcionario.name : 'Carregando...'">
        <div>
            <div class="chat-box p-3">
                <AppLoadingWrapper v-if="loading" />
                <div v-if="!loading">
                    <div v-for="comentario in comentarios" :key="comentario.id" class="flex flex-row mb-4">
                        <div class="chat-avatar">
                            <div v-if="comentario.user.urlFotoPerfil" class="user-photo-template mr-2">
                                <img :src="comentario.user.urlFotoPerfil "
                                    class="user-photo" height="100" preview v-tooltip.top="comentario.user.name" />
                            </div>
                            <Avatar v-if="!comentario.user.urlFotoPerfil"
                                :label="comentario.user.name?.split(' ')?.[1]?.[0] ? comentario.user.name[0] + comentario.user.name?.split(' ')?.[1]?.[0] : comentario.user.name[0]"
                                placeholder="Top" v-tooltip.top="comentario.user.name" class="mr-2" size="large"
                                style="background-color:#2196F3; color: #ffffff" shape="circle" />
                        </div>
                        <div class="chat-balao">
                            <div class="flex col flex-col p-0 m-0">
                                <div class="user-coment overflow-hidden" v-tooltip="comentario.user.name">{{
                                     comentario.user.name 
                                    }}
                                </div>
                                <small class="data-coment">{{
                                     $filters.formatDate(comentario.createdAt) 
                                    }}</small>
                            </div>
                            <div class="coment">{{  comentario.descricao  }}</div>
                        </div>
                        <Button v-if="comentario.user.id == profile.id" icon="pi pi-ellipsis-v"
                            class="p-button-rounded p-button-text btn-menu" v-tooltip="'Mais opções'"
                            @click="toggle($event, comentario)" aria-haspopup="true" aria-controls="overlay_menu" />
                    </div>
                </div>
                <div v-if="!loading && comentarios.length <= 0"
                    class="h-full flex align-content-center justify-content-center align-items-center">
                    <div class="flex chat-aviso">
                        Não há comentários para este agendamento ainda!
                    </div>
                    <i class="icon-aviso pi pi-comments"></i>
                </div>
            </div>
            <div class="flex mt-2">
                <div class="col-10 p-0">
                    <InputText placeholder="Adicionar comentário" @keyup.enter="enviar" v-model="comentario.descricao"
                        class="w-full h-full" :autoResize="true" rows="1" cols="30" />
                </div>
                <div class="col-2 p-0 ml-2">
                    <Button class="w-full h-full" :loading="loadingEnviar" @click="enviar" label="Enviar"
                        icon="pi pi-send" iconPos="right" />
                </div>
            </div>
        </div>
        <Menu id="overlay_menu" ref="menu" :model="menuItems" :popup="true" />
    </Dialog>
</template>
<script>
import BaseService from '../../../services/BaseService';
import * as storage from '../../../common/storage';

export default {
    props: ['agendamento'],
    data() {
        return {
            agendamentos: [],
            loading: false,
            loadingEnviar: false,
            mensagensObsStatus: [],
            comentarios: [],
            comentario: {
                descricao: null,
                agendamento: null,
            },
            menuItems: [
                {
                    label: 'Deletar',
                    icon: 'pi pi-trash',
                    command: () => this.confirmDeleteRecord(this.record)
                }]
        }
    },
    created() {
        this.$service = new BaseService('/comentarios-agendamento');
        this.load();
        this.profile = storage.getProfile();
    },
    methods: {
        async enviar() {
            this.loadingEnviar = true;
            this.comentario.agendamento = this.agendamento;
            try {
                await this.$service.save(this.comentario);
                this.load();
                this.comentario.descricao = null;
            } catch (error) {
                const messages = error?.response?.data?.details?.response?.message?.join();
                const message = messages || error?.response?.data?.message || error.message;
                this.$toast.add({ severity: 'error', summary: 'Erro ao enviar comentários, ' + message, life: 3000 });
            }
            this.loadingEnviar = false;
        },
        async load() {
            this.loading = true;
            const service = new BaseService(`/comentarios-agendamento/find-by-agendamento/${this.agendamento.id}`);
            const comentariosAgendamento = await service.findAll({});
            this.comentarios = comentariosAgendamento.data || [];
            this.loading = false;
        },
        toggle($event, record) {
            this.record = record;
            this.$refs.menu.toggle($event);
        },
        async confirmDeleteRecord(record) {
            try {
                await this.$service.remove(record.id);
                this.load();
                this.deleteRecordDialog = false;
                this.$toast.add({ severity: 'success', summary: 'Comentário excluído com sucesso!', life: 3000 });
            } catch (err) {
                console.error(err)
                this.$toast.add({ severity: 'error', summary: 'Erro ao excluir o comentário!', life: 3000 });
            }
        }
    },
}
</script>
<style lang="scss" scoped>
.user-photo-template {
	border: 1px solid #ced4da;
    border-style: solid;
    width: 40px;
    height: 40px;
    align-self: center;
    border-radius: 100%;
    overflow: hidden;
    position: relative;
}

.user-photo {
    width: initial;
    height: inherit;
    max-width: none;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}

::-webkit-scrollbar {
    width: 6px;
    background: #F4F4F4;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
}


.chat-avatar {
    top: 10px;
}

.chat-box {
    border: 1px solid #ced4da;
    border-style: solid;
    border-radius: 6px;
    height: 300px;
    overflow: auto;
}

.chat-balao {
    margin-top: 15px;
    position: relative;
    padding: 7px 21px 7px 15px;
    max-width: 300px;
    min-width: 250px;
    border-radius: 6px 23px 23px 23px;
    background-color: #edf1f3;
    box-sizing: border-box;
    overflow: hidden;
}

.data-coment {
    margin-left: 5px;
    color: #bcc2c7;
    font-size: 11px;
    display: inline-block;
    transition: 250ms linear all;
}

.user-coment {
    font-size: 13px;
    font-weight: bold;
    text-decoration: none;
    width: 100px;
    overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.chat-aviso {
    font-size: 13px;
    height: auto;
    color: #b6bfc7
}

.icon-aviso {
    font-size: 10rem;
    color: #ced4da
}

.btn-menu {
    margin-top: 25px;
    margin-left: 5px;
    color: #ced4da
}

.coment {
    font-size: 13px;
}
</style>