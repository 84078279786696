export const EsocialEventoSituacaoEnum = Object.freeze({
    ERRO_XML: 0,
    AGUARDANDO_ENVIO: 1,
    ESOCIAL_ERRO_XML: 2,
    ESOCIAL_AGUARDANDO_RETORNO: 3,
    ESOCIAL_ERRO_LOTE: 4,
    ESOCIAL_APROVADO: 5,
    SOLICITADO_RETIFICACAO: 6,
    RETIFICADO: 7,
    SOLICITADO_CANCELAMENTO: 8,
    CANCELADO: 9
});

export const EsocialEventoSituacaoEnumKey = new Map([
    [EsocialEventoSituacaoEnum.ERRO_XML, 'ERRO_XML'],
    [EsocialEventoSituacaoEnum.AGUARDANDO_ENVIO, 'AGUARDANDO_ENVIO'],
    [EsocialEventoSituacaoEnum.ESOCIAL_ERRO_XML, 'ESOCIAL_ERRO_XML'],
    [EsocialEventoSituacaoEnum.ESOCIAL_AGUARDANDO_RETORNO, 'ESOCIAL_AGUARDANDO_RETORNO'],
    [EsocialEventoSituacaoEnum.ESOCIAL_ERRO_LOTE, 'ESOCIAL_ERRO_LOTE'],
    [EsocialEventoSituacaoEnum.ESOCIAL_APROVADO, 'ESOCIAL_APROVADO'],
    [EsocialEventoSituacaoEnum.SOLICITADO_RETIFICACAO, 'SOLICITADO_RETIFICACAO'],
    [EsocialEventoSituacaoEnum.RETIFICADO, 'RETIFICADO'],
    [EsocialEventoSituacaoEnum.SOLICITADO_CANCELAMENTO, 'SOLICITADO_CANCELAMENTO'],
    [EsocialEventoSituacaoEnum.CANCELADO, 'CANCELADO']
]);

export const EsocialEventoSituacaoEnumLabel = new Map([
    [EsocialEventoSituacaoEnum.ERRO_XML, 'Erro no XML'],
    [EsocialEventoSituacaoEnum.AGUARDANDO_ENVIO, 'Aguardando envio'],
    [EsocialEventoSituacaoEnum.ESOCIAL_ERRO_XML, 'Erro no XML (eSocial)'],
    [EsocialEventoSituacaoEnum.ESOCIAL_AGUARDANDO_RETORNO, 'Aguardando retorno'],
    [EsocialEventoSituacaoEnum.ESOCIAL_ERRO_LOTE, 'Erro ao processar Lote'],
    [EsocialEventoSituacaoEnum.ESOCIAL_APROVADO, 'Aprovado'],
    [EsocialEventoSituacaoEnum.SOLICITADO_RETIFICACAO, 'Solicitado retificação'],
    [EsocialEventoSituacaoEnum.RETIFICADO, 'Retificado'],
    [EsocialEventoSituacaoEnum.SOLICITADO_CANCELAMENTO, 'Solicitado cancelamento'],
    [EsocialEventoSituacaoEnum.CANCELADO, 'Cancelado']
]);

export const EsocialEventoSituacaoEnumColor = new Map([
    [EsocialEventoSituacaoEnum.ERRO_XML, 'red'],
    [EsocialEventoSituacaoEnum.AGUARDANDO_ENVIO, '#FFD700'],
    [EsocialEventoSituacaoEnum.ESOCIAL_ERRO_XML, 'red'],
    [EsocialEventoSituacaoEnum.ESOCIAL_AGUARDANDO_RETORNO, '#C0C0C0'],
    [EsocialEventoSituacaoEnum.ESOCIAL_ERRO_LOTE, 'red'],
    [EsocialEventoSituacaoEnum.ESOCIAL_APROVADO, '#00FF7F'],
    [EsocialEventoSituacaoEnum.SOLICITADO_RETIFICACAO, '#FFD700'],
    [EsocialEventoSituacaoEnum.RETIFICADO, '#4682B4'],
    [EsocialEventoSituacaoEnum.SOLICITADO_CANCELAMENTO, '#FFD700'],
    [EsocialEventoSituacaoEnum.CANCELADO, '#4682B4']
]);
