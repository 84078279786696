<template>
    <DataTable :value="records">
        <Column field="procedimentos.name" header="Procedimento" />
        <Column field="valor" header="Valor á Pagar">
            <template #body="slotProps">
                <div v-if="slotProps.data.valor">
                        {{ $filters.formatCurrency(slotProps.data.valor ?? 0) }}
                    </div>
                    <div v-else>
                        Não Informado
                    </div>
            </template>
        </Column>
    </DataTable>
</template>

<script>

export default {
    props: {
        records: {
            type: [Object],
            required: true
        }
    }
}
</script>
