<template>
    <Dialog :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '65vw' }"
        :header="prestadores?.name ? prestadores?.code + ' - ' + prestadores?.name + ' (Cod. Duon ' + prestadores?.id + ')' : 'Carregando...'"
        :closable="false" :draggable="false">
        <AppLoadingWrapper v-if="loading" />
        <div v-if="prestadores.name && !loading" class="grid">
            <div :class="{ 'col-6': showDadosFaturamento, 'col-12': !showDadosFaturamento }">
                <Divider align="center">
                    <span class="p-tag">Dados</span>
                </Divider>

                <div class="field">
                    <b>Rua: </b>
                    {{ prestadores.street ? prestadores.street : 'Não Informado' }}
                    <b>Numero: </b>
                    {{ prestadores?.number ? prestadores.number : 'Não Informado' }}
                </div>
                <div class="field">
                    <b>Bairro: </b>
                    {{ prestadores?.neighborhood ? prestadores.neighborhood : 'Não Informado' }}
                    <b>CEP: </b>
                    {{ prestadores?.zipCode ? prestadores.zipCode : 'Não Informado' }}
                </div>
                <div class="field">
                    <b>Complemento: </b>
                    {{ prestadores.addressComplement ? prestadores.addressComplement : 'Não Informado' }}
                </div>
                <div class="field">
                    <b>Cidade: </b>
                    {{ prestadores?.city?.cidade + ' - ' + prestadores?.city?.estado }}
                </div>
                <div class="field">
                    <b>Contato: </b>
                    {{ prestadores?.phone + ' ' + prestadores?.commercialPhone }}
                </div>
                <div class="field">
                    <b>Email: </b>
                    {{ prestadores?.email ? prestadores.email : 'Não informado' }}
                </div>
            </div>
            <div class="col-6" v-if="showDadosFaturamento">
                <Divider align="center">
                    <span class="p-tag">Dados para faturamento</span>
                </Divider>

                <div class="field">
                    <b>DEPÓSITO ANTECIPADO: {{ prestadores.advanceDeposit ? 'SIM' : 'NÃO' }}</b>
                </div>
                <div v-if="prestadores.advanceDeposit" style="
                        border-color: #dee2e6;
                        border-style: solid;
                        border-width: 1px;
                        border-radius: 3px;
                        padding: 3px;
                        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
                    ">
                    <div class="field">
                        <b>Codigo do Banco: </b>
                        {{ prestadores?.codigoBanco ?? 'Não informado' }}
                    </div>
                    <div class="field">
                        <b>Nome do Banco: </b>
                        {{ prestadores?.nomeBanco ?? 'Não informado' }}
                    </div>
                    <div class="field">
                        <b>Agencia: </b>
                        {{ prestadores?.codigoAgencia ?? 'Não informado' }}
                    </div>
                    <div class="field">
                        <b>Conta: </b>
                        {{ prestadores?.contaCorrente ?? 'Não informado' }}
                    </div>
                    <div class="field">
                        <b>Titular da conta: </b>
                        {{ prestadores?.nomeTitularConta ?? 'Não informado' }}
                    </div>
                    <b>CNPJ/CPF do titular: </b>
                    {{
        prestadores?.cpfTitularConta || prestadores.cnpjTitularConta
            ? prestadores.cnpjTitularConta + ' ' + prestadores.cpfTitularConta
            : 'Não informado'
    }}
                </div>
            </div>
            <Divider align="center">
                <span class="p-tag">Horários de atendimento</span>
            </Divider>
            <DataTable :value="prestadores.openingHours" responsiveLayout="scroll">
                <Column field="weekDay" header="Dia da semana" />
                <Column header="Horário início AM">
                    <template #body="slotProps">
                        {{
        slotProps.data?.atendimentoMatutino && slotProps.data?.startHourAm &&
            slotProps.data?.startHourAm != '00:00'
            ? slotProps.data?.startHourAm
            : 'Não atende'
    }}
                    </template>
                </Column>
                <Column field="endHourAm" header="Horário fim AM">
                    <template #body="slotProps">
                        {{
        slotProps.data?.atendimentoMatutino && slotProps.data?.endHourAm && slotProps.data?.endHourAm !=
            '00:00'
            ? slotProps.data?.endHourAm
            : 'Não atende'
    }}
                    </template>
                </Column>
                <Column field="serviceTypeAm" :options="serviceTypes" optionValue="value" optionLabel="name"
                    header="Tipo atendimento AM">
                    <template #body="slotProps">
                        {{
        slotProps.data?.atendimentoMatutino && slotProps.data?.serviceTypeAm
            ? renderTipoHorario(slotProps.data?.serviceTypeAm)
            : 'Não atende'
    }}
                    </template>
                </Column>
                <Column field="startHourPm" header="Horário início PM">
                    <template #body="slotProps">
                        {{
        slotProps.data?.atendimentoVespertino && slotProps.data?.startHourPm &&
            slotProps.data?.startHourPm != '00:00'
            ? slotProps.data?.startHourPm
            : 'Não atende'
    }}
                    </template>
                </Column>
                <Column field="endHourPm" header="Horário fim PM">
                    <template #body="slotProps">
                        {{
        slotProps.data?.atendimentoVespertino && slotProps.data?.endHourPm && slotProps.data?.endHourPm
                        != '00:00'
                        ? slotProps.data?.endHourPm
                        : 'Não atende'
                        }}
                    </template>
                </Column>
                <Column field="serviceTypePm" :options="serviceTypes" optionValue="value" optionLabel="name"
                    header="Tipo atendimento PM ">
                    <template #body="slotProps">
                        {{
                        slotProps.data?.atendimentoVespertino && slotProps.data?.serviceTypePm
                        ? renderTipoHorario(slotProps.data?.serviceTypePm)
                        : 'Não atende'
                        }}
                    </template>
                </Column>
            </DataTable>
        </div>

        <template #footer>
            <Button label="Voltar" icon="pi pi-arrow-left" class="p-button-text"
                @click="$emit('openCloseDadosPrestador')" />
        </template>
    </Dialog>
</template>
<script>
import BaseService from '../../../services/BaseService';

export default {
    emits: ['openCloseDadosPrestador'],
    props: {
        showDadosFaturamento: {
            type: Boolean,
            default: true
        },
        objPrestador: {
            type: [Object],
            required: true
        }
    },
    data() {
        return {
            prestadores: [],
            headerText: this.prestadores?.code + ' - ' + this.prestadores?.name + '(Cod. Duon ' + this.prestadores?.id + ')',
            loading: false,
            serviceTypes: [
                { name: 'Ordem de chegada', value: 'ORDEM_CHEGADA' },
                { name: 'Horário marcado', value: 'HORARIO_MARCADO' }
            ]
        };
    },
    created() {
        this.$service = new BaseService('/suppliers');
        this.loadPrestadores(this.objPrestador.id);
    },
    methods: {
        async loadPrestadores(id) {
            this.loading = true;
            const { data } = await this.$service.findById(id);
            this.prestadores = data;
            if (this.prestadores) {
                this.loading = false;
            }
        },
        renderTipoHorario(tipo) {
            switch (tipo) {
                case 'ORDEM_CHEGADA':
                    return 'Ordem de chegada';
                case 'HORARIO_MARCADO':
                    return 'Horário marcado';
                default:
                    return 'Inconsistênte';
            }
        }
    }
};
</script>
