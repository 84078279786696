<template>
    <Dialog :style="{ width: '50vw', maxHeight: '40vw' }" header="Depósito Antecipado" :modal="true">
        <AppLoadingWrapper v-if="loading" />
        <DataTable 
            v-if="!loading && !hasError"
            ref="grid" 
            :value="records" 
            v-model:expandedRows="expandedRows"
        >
            <Column :expander="true" headerStyle="width: 3rem" />
            <Column field="prestador" header="Prestador" />
            <Column header="Valores">
                <template #body="slotProps">
                    <Button
                        type="button" 
                        icon="pi pi-external-link"
                        class="p-button-text" 
                        v-tooltip.bottom="'Consultar valores'"
                        @click="consultarValoresEmNovaAba(slotProps.data.idPrestador)"
                    />
                </template>
            </Column>
            <Column header="Total">
                <template #body="slotProps">
                    <div v-if="slotProps.data.total">
                        {{ $filters.formatCurrency(slotProps.data.total ?? 0) }}
                    </div>
                    <div v-else>
                        Não Informado
                    </div>
                </template>
            </Column>
            <Column header="Comprovante">
                <template #body="slotProps">
                    {{slotProps.data.anexo}}
                    <Button
                        :style="comprovanteStyle(slotProps.data)"
                        type="button" 
                        icon="pi pi-paperclip"
                        class="p-button-text" 
                        v-tooltip.bottom="comprovanteTooltip(slotProps.data)"
                        @click="openAnexoDialog(slotProps.data)"
                    />
                </template>
            </Column>
            <template #expansion="{ data }">
                <DetalhesExamesDepositoAntecipado :records="data.items" />
            </template>
        </DataTable>
        <div v-if="!loading && hasError" class="flex flex-column align-items-center justify-content-center">
            <i class="pi pi-times-circle mr-3" style="font-size: 2rem"/>
            <span style="font-size: 2rem">Sem dados de exibição!</span>
        </div>
        <template #footer>
            <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="onClose" />
            <Button label="Confirmar" icon="pi pi-check" class="p-button-outlined p-button-success" @click="onConfirmDepositoAntecipado" />
        </template>
        <AppAnexoDialog 
            v-if="showAnexoDialog" 
            v-model:visible="showAnexoDialog" 
            title='Comprovante do depósito'
            tipo='COMPROVANTE' 
            origem='DEPOSITO_ANTECIPADO' 
            v-model:origemId="dadosComprovante.idAgendamentoProcedimento"
            @onClickCloseAnexo="onClickCloseAnexo" 
            :modal="false" 
            @onUpload="onUploadAnexo" 
            @onDelete="onDeleteAnexo">
        </AppAnexoDialog>
    </Dialog>
</template>

<script>
import { getClientBase } from '@/services/http';
import DetalhesExamesDepositoAntecipado from './DetalhesExamesDepositoAntecipado.vue';

export default {
    components: {
        DetalhesExamesDepositoAntecipado
    },
    emits:['onConfirm', 'onClose'],
    props: {
        idAgendamento: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            loading: false,
            hasError: false,
            records: [{
                anexos: null
            }],
            anexos: [],
            expandedRows: [],
            showAnexoDialog: false,
            dadosComprovante: null
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            this.loading = true;
            await this.loadDadosDeposito();
            await this.loadAnexos();
            this.loading = false;
        },
        async loadDadosDeposito() {
            try {
                this.hasError = false;
                const { data } = await getClientBase().get('/deposito-antecipado/agendamento-procedimento/agendamento/' + this.idAgendamento);
                this.records = data;
            } catch (error) {
                this.hasError = true;
                this.$toast.add({ severity: 'error', summary: 'Problemas ao carregar informações!', life: 3000 });
            }
        },
        async loadAnexos() {
            this.anexos = [];
            for (const record of this.records) {
                const { data } = await getClientBase().get(`/anexo/DEPOSITO_ANTECIPADO/` + record.idAgendamentoProcedimento);
                this.anexos.push(...data);
            }

            this.records.forEach(record => {
                record.temAnexo = !!this.anexos.find(p => p.origemId === record.idAgendamentoProcedimento && p.tipo === 'COMPROVANTE');
            });
        },
        consultarValoresEmNovaAba(idPrestador) {
            const url = window.location.origin;
            window.open(`${url}/#/supplier/${idPrestador}/procedures`);
        },
        comprovanteStyle(record) {
            if (record.temAnexo) {
                return {
                    'color': 'var(--green-500)'
                }
            }

            return {
                'color': 'var(--gray-500)'
            }
        },
        comprovanteTooltip(record) {
            if (record.temAnexo) {
                return 'Comprovante anexado';
            }

            return 'Anexar comprovante';
        },
        openAnexoDialog(record) {
            this.dadosComprovante = record;
            this.showAnexoDialog = true;
        },
        async onUploadAnexo() {
            await this.loadAnexos();
        },
        async onDeleteAnexo() {
            await this.loadAnexos();
        },
        onClickCloseAnexo() {
            this.showAnexoDialog = false;
        },
        onClose() {
            this.$emit('onClose');
        },
        onConfirmDepositoAntecipado() {
            const agendamento = this.records[0]?.items[0]?.agendamento;
            const itemsDeposito = this.records[0]?.items;
            const faltaAnexo = !!this.records.find(p => !p.temAnexo);

            if (!agendamento.id) {
                this.$toast.add({ severity: 'error', summary: 'Agendamento não vinculado!', life: 3000 });
                return;
            }

            if (faltaAnexo) {
                this.$toast.add({ severity: 'warn', summary: 'Necessário anexar todos os comprovantes de depósito antes de confirmar o agendamento.', life: 3000 });
                return;
            }

            this.$emit('onConfirm', agendamento, itemsDeposito);
        }
    }
}
</script>
